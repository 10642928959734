import { Text } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ShopBuildYourOwnPackage } from '../../../../../../../shop-api-client';
import {
  selectAddedUnits,
  selectConfiguration,
  selectIsStepCompleted,
  selectPackageItemMap,
} from '../../../../../../redux/selectors/configurations.selectors';
import { selectPriceSheet } from '../../../../../../redux/selectors/priceSheet.selectors';
import {
  setCompletedSteps,
  setEditStep,
} from '../../../../../../redux/slices/configurations.slice';
import { useAppSelector } from '../../../../../../redux/store';
import { ITEM, ITEMS } from '../../../../../../shared/constants/labels.constants';
import useToast from '../../../../../../shared/hooks/useToast';
import { Params } from '../../../../../../shared/types/router';
import { pluralize } from '../../../../../../shared/utils';
import { BUILD_YOUR_PACKAGE } from '../../../constants';
import { getBuildYourOwnStep } from '../../../utils';
import BuildYourOwn from '../../BuildYourOwn';
import CompletedSection from '../../CompletedSection';
import EditorSectionContainer from '../../EditorSectionContainer';

const BYOPItemSelection = () => {
  const { products } = useSelector(selectPriceSheet);
  const { editPackage } = useSelector(selectConfiguration);
  const addedUnits = useSelector(selectAddedUnits);
  const packageItems = useSelector(selectPackageItemMap);
  const stepName = useMemo(() => getBuildYourOwnStep(), []);
  const completed = useAppSelector(state => selectIsStepCompleted(state, stepName));

  const { packageID } = useParams<Params>();
  const dispatch = useDispatch();
  const intl = useIntl();
  const shopPackage = products[packageID] as ShopBuildYourOwnPackage;
  const toast = useToast();

  const handleSave = () => {
    if (addedUnits < shopPackage.minUnits) {
      const remaining = shopPackage.minUnits - addedUnits;
      // TODO this toast is temporary until the Modal is built that will be used instead:
      return toast({
        title: `You haven't used all of your available ${pluralize(
          2,
          shopPackage.unitLabel,
          false,
        )} yet. Choose ${remaining} more ${pluralize(
          remaining,
          shopPackage.unitLabel,
          false,
        )} worth of items before continuing.`,
      });
    }

    dispatch(setEditStep(null));
    dispatch(setCompletedSteps({ [stepName]: true }));
  };

  const handleEdit = () => {
    dispatch(setEditStep(stepName));
  };

  if (completed) {
    const addedCount = editPackage?.products.filter(
      p => packageItems[p.priceSheetItemID].price > 0,
    ).length;
    return (
      <CompletedSection heading={BUILD_YOUR_PACKAGE} onEdit={handleEdit}>
        <Text>
          {intl.formatMessage(
            {
              id: 'BYOPItemSection.countOfItemsAdded',
              defaultMessage: '{count} {item} added',
            },
            {
              count: addedCount,
              item: addedCount === 1 ? ITEM : ITEMS,
            },
          )}
        </Text>
      </CompletedSection>
    );
  }

  return (
    <EditorSectionContainer onSave={handleSave}>
      <BuildYourOwn />
    </EditorSectionContainer>
  );
};

export default BYOPItemSelection;
