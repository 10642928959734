import {
  Box,
  BoxProps,
  Flex,
  Heading,
  HStack,
  SimpleGrid,
  SimpleGridProps,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import {
  ImageRequirementType,
  ShopBackground,
  ShopImage,
} from '../../../../../../../../shop-api-client';
import { ShopFavorite } from '../../../../../../../../shop-api-client/models/Cart';
import { selectBackgroundsMap } from '../../../../../../../redux/selectors/background.selectors';
import { selectEditItemAvailableImages } from '../../../../../../../redux/selectors/configurations.selectors';
import { selectGallery } from '../../../../../../../redux/selectors/gallery.selectors';
import { useAppSelector } from '../../../../../../../redux/store';
import ThumbnailWithBackground from '../../../../../../../shared/components/ThumbnailWithBackground';
import { ThumbnailWithBackgroundProps } from '../../../../../../../shared/components/ThumbnailWithBackground/ThumbnailWithBackground';
import { ImageAndBackground } from '../../../../../../../shared/types/image';
import { Params } from '../../../../../../../shared/types/router';
import { validateImageRequirement } from '../../../../../utils';
import { ON, THUMB_GRID_MAX_HEIGHT, THUMB_GRID_MAX_WIDTH } from '../../../../constants';

interface Props extends SimpleGridProps {
  boxProps?: BoxProps;
  heading: string;
  imageMaxHeight?: string;
  imageMaxWidth?: string;
  imageRequirementType?: ImageRequirementType | null;
  presetImages: (ImageAndBackground | ShopFavorite)[];
  selectedImagesCounter?: Record<string, number>;
  selectedImages: Record<string, ImageAndBackground>;
  selectImage(img: ShopImage, background?: ShopBackground): void;
  showAsHorizontalList?: boolean;
  showErrorOutline?: boolean;
  showImageName?: boolean;
  showNumImages?: boolean;
  thumbnailProps?: ThumbnailWithBackgroundProps;
}

/**
 * Renders images that have are "Preset"/"Complete" (either not GS, or GS img + bg pair)
 * This is currently used for rendering favorite images / recently used images (images already in-cart)
 * So none of them require any further modifications (except for crop)
 */
const ImageNodeDisplayPreset = ({
  boxProps,
  heading,
  imageMaxHeight = THUMB_GRID_MAX_HEIGHT,
  imageMaxWidth = THUMB_GRID_MAX_WIDTH,
  imageRequirementType,
  presetImages,
  selectedImagesCounter,
  selectedImages,
  selectImage,
  showAsHorizontalList,
  showErrorOutline,
  showImageName = true,
  showNumImages = true,
  thumbnailProps,
  ...rest
}: Props) => {
  const { key } = useParams<Params>();
  const { images, type } = useAppSelector(state => selectGallery(state, key));
  const { products: editItemToImageMap } = useAppSelector(selectEditItemAvailableImages);
  const backgroundsMap = useAppSelector(selectBackgroundsMap);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const availableImages = Object.values(editItemToImageMap).flat();

  if (!presetImages.length) {
    return null;
  }

  // Filter out images that don't meet req type
  const validImages = presetImages.filter(presetImage => {
    const image = images[presetImage.imageName];
    const isAvailable = availableImages.some(i => i.internalName === image.internalName);
    if (!isAvailable) {
      return false;
    }
    if (type === 'standard') {
      return true;
    }
    return validateImageRequirement(image, imageRequirementType);
  });

  if (!validImages.length) {
    return null;
  }

  const renderImages = () => {
    return validImages.map((i, index) => {
      const img = images[i.imageName];
      const bg = i.backgroundID ? backgroundsMap[i.backgroundID] : undefined;

      const isSelected = !!selectedImages[`${img.internalName}-${bg?.id}`];
      const imageCounter = selectedImagesCounter?.[`${img.internalName}-${bg?.id}`];
      const additionalThumbnailProps = isSelected ? thumbnailProps : {};

      let borderColor = showErrorOutline ? 'error' : 'transparent';
      if (isSelected) {
        borderColor = 'brand';
      }

      const borderRadius = isMobile ? '3px' : '5px';
      const borderWidth = isMobile ? '3px' : '4px';
      const borderPadding = isMobile ? '2px' : '4px';

      return (
        <Flex
          key={`${heading}-${index}`}
          direction="column"
          alignItems="center"
          marginBottom={showAsHorizontalList ? 3 : '15px'}
          justifyContent="space-between"
        >
          <Box
            data-test={`shop-photo-selection-${img.displayName}`}
            borderColor={borderColor}
            borderWidth={borderWidth}
            borderRadius={borderRadius}
            marginX={showAsHorizontalList ? '4px' : undefined}
            padding={borderPadding}
          >
            <ThumbnailWithBackground
              src={img.sources.thumb}
              background={bg?.sources?.thumb}
              badgeText={imageCounter ? `${imageCounter}` : undefined}
              containerOnClick={() => selectImage(img, bg)}
              maxWidth={imageMaxWidth}
              maxHeight={imageMaxHeight}
              isActive={isSelected}
              showBadge={!!imageCounter}
              _hover={{
                cursor: 'pointer',
              }}
              {...additionalThumbnailProps}
            />
          </Box>
          {showImageName && (
            <Flex justifyContent="flex-start" direction="column" maxWidth={imageMaxWidth}>
              <Text fontSize="xs" noOfLines={1} maxWidth={imageMaxWidth}>
                {img.displayName}
              </Text>
              {bg && (
                <Text fontSize="xs" noOfLines={1} as="i">
                  {ON} {bg.name}
                </Text>
              )}
            </Flex>
          )}
        </Flex>
      );
    });
  };

  return (
    <Box {...boxProps}>
      {!showAsHorizontalList && (
        <Heading size="xs" marginY={2}>
          {heading} {showNumImages && `(${validImages.length})`}
        </Heading>
      )}
      {showAsHorizontalList ? (
        <HStack {...rest}>{renderImages()}</HStack>
      ) : (
        <SimpleGrid columns={{ base: 2, md: 3 }} {...rest}>
          {renderImages()}
        </SimpleGrid>
      )}
    </Box>
  );
};

export default ImageNodeDisplayPreset;
