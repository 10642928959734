import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ShopPackage, ShopProduct } from '../../../../shop-api-client';
import { selectAccount } from '../../../redux/selectors/account.selectors';
import { validateGalleryImageSupport } from '../../../redux/selectors/configurations.selectors';
import { selectGallery } from '../../../redux/selectors/gallery.selectors';
import {
  selectCanAutoAddMap,
  selectCanSelectAndBuy,
} from '../../../redux/selectors/interactions.selectors';
import { selectPriceSheet } from '../../../redux/selectors/priceSheet.selectors';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { addCartItem } from '../../../redux/thunks/cart.thunks';
import useSearchParams from '../../../shared/hooks/useSearchParams';
import { Params } from '../../../shared/types/router';
import { formatCurrency } from '../../../shared/utils';
import useConfigurationRouter from '../Configuration/useConfigurationRouter';
import {
  isConfigurable,
  requiresPerImageBG,
  requiresPerProductBG,
  shapeEditPackage,
  shapeEditProduct,
} from '../Configuration/utils';
import { DESCRIPTION, POSE_OPTIONS } from '../constants';
import FittedProductImage from '../FittedProductImage';
import DetailsSection from '../ProductDetails/DetailsSection';
import PackageItemList from '../ProductDetails/PackageItemList';
import {
  getBackgroundFromSets,
  getCustomizeLabel,
  getFirstAssignedImage,
  getPoseDescription,
  getProductDescription,
} from '../utils';

interface Props {
  isOpen: boolean;
  onClose(): void;
  product: ShopProduct | ShopPackage;
}

const QuickViewModal = ({ isOpen, onClose, product }: Props) => {
  const { currency } = useAppSelector(selectAccount);
  const gallery = useSelector(selectGallery);
  const {
    backgroundSets,
    imageOptionMap,
    offersStatusMap,
    preOrderBackgroundSelectionType,
    productNodeMap,
  } = useSelector(selectPriceSheet);
  const canAutoAddMap = useSelector(selectCanAutoAddMap);
  const canSelectAndBuy = useSelector(selectCanSelectAndBuy);
  const validForGallery = useAppSelector(state => validateGalleryImageSupport(state, product));

  const { routeToConfigurationPath } = useConfigurationRouter();
  const { key } = useParams<Params>();
  const { getParamsValue } = useSearchParams();

  const dispatch = useAppDispatch();

  const { images, isGreenScreen, isPreOrder } = gallery;
  const hasImgOptions = !!Object.keys(imageOptionMap).length && product.type !== 'nonPrintProduct';

  const canEditPerProductBG = requiresPerProductBG(
    product.type,
    preOrderBackgroundSelectionType,
    isGreenScreen,
    isPreOrder,
    backgroundSets,
  );
  const canEditPerImageBG = requiresPerImageBG(
    product.type,
    preOrderBackgroundSelectionType,
    isGreenScreen,
    isPreOrder,
    backgroundSets,
  );

  const canAddToCart =
    canAutoAddMap[product.id] ||
    (!hasImgOptions &&
      !canEditPerProductBG &&
      !canEditPerImageBG &&
      !isConfigurable(isPreOrder, product, productNodeMap));

  const categoryOffers = offersStatusMap[product.categoryID];
  const isPackage = product.type === 'package-byop' || product.type === 'package';
  const isLocked = categoryOffers?.isLocked;
  const selectAndBuyRequiresConfig = canSelectAndBuy[product.id] && !canAddToCart;
  const customizeLabel = getCustomizeLabel(
    !validForGallery,
    canAddToCart,
    false,
  );

  const handleAddOrCustomize = async () => {
    if (canAddToCart) {
      // Get image values from params:
      const backgroundID = getParamsValue('bg');
      const imageName = getParamsValue('image');

      const image = images[imageName];
      const background = image?.isGreenScreen
        ? getBackgroundFromSets(backgroundSets, parseInt(backgroundID))
        : undefined;
      let editItem;

      if (product.type === 'package' || product.type === 'package-byop') {
        editItem = shapeEditPackage(product, productNodeMap, image, background, gallery);
      } else {
        editItem = shapeEditProduct(product, productNodeMap, image, background);
      }
      await dispatch(addCartItem(editItem));
      onClose();
    } else {
      routeToConfigurationPath(key, product, gallery, selectAndBuyRequiresConfig);
    }
  };

  return (
    <Modal blockScrollOnMount isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent borderRadius="0" margin="20px" maxWidth="800px" minHeight="600px">
        <Box height="35px" width="100%">
          <ModalCloseButton />
        </Box>
        <ModalBody>
          <Flex direction="row" flex="auto" width="inherit">
            <Box width="50%" paddingBottom={5}>
              <Center maxHeight="600px" minHeight="375px">
                <FittedProductImage
                  key={product.id}
                  image={getFirstAssignedImage(product)}
                  isLocked={isLocked}
                  maxHeight={600}
                  opacity={validForGallery ? 1 : 0.25}
                  product={product}
                  width={375}
                />
              </Center>
              <Button
                disabled={isLocked || !validForGallery}
                fontSize="sm"
                marginY={'20px'}
                onClick={handleAddOrCustomize}
                width="100%"
                data-test={`product-details-main-customize-button-${product.name}`}
              >
                {customizeLabel}
              </Button>
            </Box>
            <Flex direction="column" paddingX="20px" width="50%">
              <Box textAlign="left">
                <Heading size="lg">{product.name}</Heading>
                <Text
                  color="brand"
                  fontFamily="ITC Avant Garde Gothic Demi"
                  fontSize="18px"
                  marginY="5px"
                >
                  {formatCurrency(product.price, currency)}
                </Text>
              </Box>
              {isPackage && (
                <>
                  <PackageItemList packageID={product.id} />
                  {product.type !== 'package' && (
                    <PackageItemList packageID={product.id} notIncluded />
                  )}
                </>
              )}
              <DetailsSection
                heading={DESCRIPTION}
                headingFontSize="sm"
                hideBorder={!isPackage && !product.options.length}
                marginBottom="initial"
              >
                <Box marginBottom={5}>
                  <Text
                    fontSize="md"
                    marginTop="8px"
                    data-test={`product-details-main-product-description-${product.id}`}
                  >
                    {getProductDescription(product)}
                  </Text>
                </Box>
              </DetailsSection>
              {isPackage && (
                /* TODO SC-272: When the product option details is restored, replace the opening DetailsSection tag with the commented one below
          <DetailsSection heading={POSE_OPTIONS} headingFontSize="sm" isCollapsible hideBorder={!product.availableProducts.some(p => p.options.length)}>
          */
                <DetailsSection
                  heading={POSE_OPTIONS}
                  headingFontSize="sm"
                  isCollapsible
                  hideBorder
                >
                  <Text
                    fontSize="md"
                    marginBottom={3}
                    marginTop="8px"
                    data-test={`product-details-main-product-pose-description-${product.name}`}
                  >
                    {getPoseDescription(product, currency)}
                  </Text>
                </DetailsSection>
              )}
              {/* TODO SC-272: Un-comment after MVP when criteria is provided  */}
              {/* {!!product.options.length && (
          <DetailsSection heading={OPTIONS_AVAILABLE} headingFontSize="sm" isCollapsible>
          WIP: Include option details
          </DetailsSection>
        )} */}
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default QuickViewModal;
