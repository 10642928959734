import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../redux/store';
import { initializeSelectAndBuy } from '../../redux/thunks/interactions.thunks';
import useSearchParams from './useSearchParams';
import { useSelector } from 'react-redux';
import { selectBuyModeImage } from '../../redux/selectors/interactions.selectors';
import { selectGallery } from '../../redux/selectors/gallery.selectors';

export const useStickySelectAndBuyParams = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const { getParamsValue } = useSearchParams();

    const { images } = useSelector(selectGallery);
    const { buyModeBackground, buyModeImage } = useSelector(selectBuyModeImage);

    const bgFromParams = getParamsValue('bg');
    const imgFromParams = getParamsValue('image');

    useEffect(() => {
        if (!buyModeImage && imgFromParams) {
            const image = images[imgFromParams];
            dispatch(initializeSelectAndBuy(imgFromParams, parseInt(bgFromParams)));
            if (!image) {
                history.replace({ search: '' });
            }
        } else if (buyModeImage && !imgFromParams) {
            history.replace({
                search: `?image=${buyModeImage.internalName}${buyModeBackground ? `&bg=${buyModeBackground.id}` : ''
                    }`,
            });
        }
    }, [
        imgFromParams,
        bgFromParams,
        buyModeImage,
        buyModeBackground,
        images,
        dispatch,
        history,
    ]);
}; 