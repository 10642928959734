import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Spacer,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../redux/store';
import { initializeSelectAndBuy } from '../../../redux/thunks/interactions.thunks';
import Thumbnail from '../../../shared/components/Thumbnail';
import { BANNER_Z_INDEX, CANCEL } from '../../../shared/constants';
import { useStickySelectAndBuyParams } from '../../../shared/hooks/useStickySelectAndBuyParams';
import { Params } from '../../../shared/types/router';
import { getNameWithoutExt } from '../../../shared/utils';
import { selectBuyModeImage, selectCanSelectAndBuy } from '../../../redux/selectors/interactions.selectors';
import { useSelector } from 'react-redux';

const SelectAndBuyBanner = () => {
  const { key, productID } = useParams<Params>();
  const bannerRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const intl = useIntl();
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const canSelectAndBuy = useSelector(selectCanSelectAndBuy);
  const { buyModeBackground, buyModeImage } = useSelector(selectBuyModeImage);

  // Used on desktop:
  const shopForSelectedImg = intl.formatMessage({
    id: 'selectAndBuyBanner.shopForSelectedImg',
    defaultMessage: 'Shopping for selected image',
  });

  // Used on mobile:
  const shoppingFor = intl.formatMessage({
    id: 'selectAndBuyBanner.shoppingFor',
    defaultMessage: 'Shopping for',
  });

  const on = intl.formatMessage({
    id: 'selectAndBuyBanner.on',
    defaultMessage: 'on',
  });

  // Ensure that the sticky select and buy params are set when the component is mounted
  // So that navigation to any of the product configuration will maintain the select and buy mode
  useStickySelectAndBuyParams();

  const handleCancelBuyMode = () => {
    dispatch(initializeSelectAndBuy());
    history.replace({ search: undefined });
  };

  const handleChangePhoto = () => {
    const appendToPath = buyModeBackground ? `/bg/${buyModeBackground.id}` : '';
    history.push(`/${key}/photos/all/photo/${buyModeImage!.internalName}${appendToPath}`);
  };

  if (!buyModeImage || (productID && !canSelectAndBuy[productID])) {
    return null;
  }

  const renderActions = () => {
    if (isMobile) {
      return (
        <Flex alignItems="center">
          <Button
            data-test="select-and-buy-banner-change-photo"
            fontFamily="heading"
            onClick={handleChangePhoto}
            paddingX={3}
            textDecoration="none"
            variant="linkWhite"
          >
            {intl.formatMessage({
              id: 'buyBanner.change',
              defaultMessage: 'Change',
            })}
          </Button>
          <Divider borderColor="white" height="25px" orientation="vertical" borderLeftWidth={2} />
          <Button
            data-test="select-and-buy-banner-cancel"
            fontFamily="heading"
            onClick={handleCancelBuyMode}
            paddingX={3}
            textDecoration="none"
            variant="linkWhite"
          >
            {CANCEL}
          </Button>
        </Flex>
      );
    }

    return (
      <Flex alignItems="center">
        <Button
          marginX="40px"
          onClick={handleChangePhoto}
          variant="linkWhite"
          data-test="select-and-buy-banner-change-photo"
        >
          {intl.formatMessage({
            id: 'buyBanner.changePhoto',
            defaultMessage: 'Change Photo',
          })}
        </Button>
        <Button
          fontSize="md"
          height="45px"
          marginLeft={3}
          onClick={handleCancelBuyMode}
          variant="secondary"
          width="125px"
          data-test="select-and-buy-banner-cancel"
        >
          {CANCEL}
        </Button>
      </Flex>
    );
  };

  return (
    <Flex
      align="center"
      backgroundColor="brand"
      height={{ base: '76px', lg: '100px' }}
      paddingX={{ base: 4, md: 8, lg: '10%' }}
      position={{ base: 'relative', lg: 'sticky' }}
      ref={bannerRef}
      top={0}
      width="100%"
      zIndex={BANNER_Z_INDEX}
    >
      <Flex minWidth="60px">
        <Thumbnail
          align="center"
          background={buyModeImage.isGreenScreen ? buyModeBackground?.sources.full : undefined}
          hideSpinner
          maxHeight={{ base: '72px', lg: '90px' }}
          src={buyModeImage.sources.thumb}
        />
      </Flex>
      <Flex direction="row" width="100%" alignItems="center">
        <Box marginLeft={{ base: 5, md: 6, lg: 20 }} paddingBottom={2}>
          <Heading color="white" fontSize={['sm', 'lg']} noOfLines={1}>
            {isMobile ? shoppingFor : shopForSelectedImg}
          </Heading>
          <Text
            color="white"
            fontSize={['sm', 'sm', 'md']}
            minWidth="60px"
            noOfLines={1}
            paddingTop={{ base: 0, lg: '5px' }}
            whiteSpace="normal"
            data-test="select-and-buy-banner-image-name"
          >
            {buyModeImage.displayName}{' '}
            {!!buyModeBackground && `${on} ${getNameWithoutExt(buyModeBackground.name)}`}
          </Text>
        </Box>
        <Spacer />
        {renderActions()}
      </Flex>
    </Flex>
  );
};

export default SelectAndBuyBanner;
