import { Box, Flex, GridItem, Heading, Text } from '@chakra-ui/react';
import * as EmailValidator from 'email-validator';
import { isValidPhoneNumber } from 'libphonenumber-js';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { api, CustomDataSpecField, SelectedCustomDataSpec } from '../../../../shop-api-client';
import {
  selectCheckoutData,
  selectCheckoutVisitKeys,
} from '../../../redux/selectors/checkout.selectors';
import { selectGallery } from '../../../redux/selectors/gallery.selectors';
import { selectCurrentVisitKey } from '../../../redux/selectors/visitor.selectors';
import { setCheckoutCustomDataSpec } from '../../../redux/slices/checkout.slice';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import DatePicker from '../../../shared/components/DatePicker';
import FloatingLabelInput from '../../../shared/components/FloatingLabelInput';
import FloatingLabelSelect from '../../../shared/components/FloatingLabelSelect';
import { Params } from '../../../shared/types/router';
import { formatDate } from '../../../shared/utils';
import { CheckoutStep } from '../CheckoutData';
import { CHECKOUT_ERROR, INPUT_LABELS } from '../constants';
import { getInvalidMessage } from '../utils';

interface CustomFormStep extends CheckoutStep {
  setLoading: Dispatch<SetStateAction<boolean>>;
}

const CheckoutCustomForm = ({
  isActive,
  isComplete,
  setLoading,
  setValidateCallback,
  stepKey,
}: CustomFormStep) => {
  const checkoutData = useSelector(selectCheckoutData);
  const checkoutVisitKeys = useSelector(selectCheckoutVisitKeys);
  const currentVisitKey = useSelector(selectCurrentVisitKey);
  const { customDataSpecID } = useAppSelector(state =>
    selectGallery(state, checkoutVisitKeys?.[0]),
  );

  const [customFormData, setCustomFormData] = useState<CustomDataSpecField[]>([]);
  const [data, setData] = useState<SelectedCustomDataSpec>({});
  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const [errorState, setErrorState] = useState(false);

  // Hooks
  const { checkoutID, key } = useParams<Params>();
  const dispatch = useAppDispatch();

  // Validate callback:
  // Validate data + check for errors
  // Send API/Redux updates when next button is clicked
  const validate = useCallback(async () => {
    if (Object.keys(errors).length || !checkoutID) {
      setErrorState(true);
      return false;
    }

    dispatch(setCheckoutCustomDataSpec(data));
    const result = await api.updateCheckout(currentVisitKey!, checkoutID, {
      ...checkoutData,
      customDataSpec: data,
    });
    return !!result;
  }, [checkoutID, checkoutData, currentVisitKey, data, dispatch, errors]);

  useEffect(() => {
    if (!isActive) {
      return;
    }
    setValidateCallback({ validate });
  }, [isActive, setValidateCallback, validate]);

  // Pre-fill with data if saved/in Redux
  useEffect(() => {
    setData(checkoutData.customDataSpec);
  }, [checkoutData]);

  // Get the custom data fields from gallery config using customSpecID
  useEffect(() => {
    if (!customDataSpecID) {
      return;
    }

    const getCustomFormData = async () => {
      try {
        const customFormData = await api.getCustomForm(key, customDataSpecID);
        setCustomFormData(customFormData.fields);
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
    getCustomFormData();
  }, [customDataSpecID, key, setLoading]);

  useEffect(() => {
    // Create lookup of errors for each field based on type/requiredness
    const errors = customFormData.reduce<Record<string, boolean>>((errors, field) => {
      if (field.requiredness === 'required' && !data?.[field.field]) {
        if (
          (field.field === 'phone1' || field.field === 'phone2') &&
          !isValidPhoneNumber(data?.[field.field], 'US')
        ) {
          errors[field.field] = true;
        } else if (field.field === 'email' && !EmailValidator.validate(data?.[field.field])) {
          errors[field.field] = true;
        } else {
          errors[field.field] = true;
        }
      }

      return errors;
    }, {});

    setErrors(errors);
  }, [data, stepKey, customFormData, setErrorState]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  // Determine what type of input to render based on field type
  const renderFieldType = (field: CustomDataSpecField) => {
    // Renders an input type
    if (field.type === 'text') {
      return (
        <Box marginBottom={5}>
          <Heading fontSize="md" marginBottom={2}>
            {field.name}
          </Heading>

          <FloatingLabelInput
            aria-label={field.name || INPUT_LABELS[field.subjectMap]}
            autoComplete="new-dont-fill" //disable autofill (any string matching new-*)
            invalidMessage={getInvalidMessage(field.name || INPUT_LABELS[field.subjectMap])}
            isInvalid={errorState && errors[field.field]}
            isRequired={field.requiredness === 'required'}
            name={field.field}
            onChange={handleChange}
            inputLabel={field.value || INPUT_LABELS[field.subjectMap]}
            placeholder={field.value || INPUT_LABELS[field.subjectMap]}
            type={field.inputType || field.type}
            value={data?.[field.field] || ''}
            width={{ base: '100%', lg: '500px' }}
          />
        </Box>
      );
    }

    // Renders either a selection type or a boolean type that looks like a selection w/ 'yes/no'
    if ((field.type === 'selection' && field.selections) || field.type === 'boolean') {
      const selections = field.type === 'selection' ? field.selections || [] : ['Yes', 'No'];

      return (
        <FloatingLabelSelect
          aria-label={INPUT_LABELS[field.subjectMap] || field.name}
          invalidMessage={CHECKOUT_ERROR}
          isInvalid={errorState && errors[field.field]}
          isRequired={field.requiredness === 'required'}
          marginTop={4}
          name={field.field}
          onChange={handleChange}
          placeholder={field.name}
          value={data?.[field.field] || ''}
          width={{ base: '100%', lg: '500px' }}
          autoComplete="new-dont-fill"
        >
          {selections.map((selection, index) => (
            <option key={index} value={selection}>
              {selection}
            </option>
          ))}
        </FloatingLabelSelect>
      );
    }

    // Renders a datepicker
    if (field.type === 'date') {
      return (
        <DatePicker
          invalidMessage="Please pick a valid date"
          isRequired={field.requiredness === 'required'}
          marginTop={4}
          onChange={date => setData({ ...data, [field.field]: formatDate(`${date}`) || '' })}
          placeholder={field.name}
          value={data?.[field.field] || ''}
          autoComplete="new-dont-fill"
        />
      );
    }
  };

  const renderComplete = () => (
    <Flex flexFlow="column" paddingX={10} paddingBottom={8}>
      {customFormData.map((field, index) => (
        <Flex
          flexFlow="row"
          key={index}
          display="inline"
          overflowWrap="break-word"
          marginBottom={1}
        >
          <Text variant="inline" fontSize="md">
            {field.name}
          </Text>
          <Text marginX={1} variant="inline" fontSize="md">
            :
          </Text>
          <Text variant="inline" fontWeight="bold" fontSize="md">
            {data[field.field]}
          </Text>
        </Flex>
      ))}
    </Flex>
  );

  return (
    <>
      {isComplete && !isActive && renderComplete()}
      {isActive && (
        <Flex flexFlow="column">
          {customFormData?.map((field, index) => (
            <GridItem key={index}>{renderFieldType(field)}</GridItem>
          ))}
        </Flex>
      )}
    </>
  );
};

export default CheckoutCustomForm;
