import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import { Elements, PaymentMethodMessagingElement } from '@stripe/react-stripe-js';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ShopBackground, ShopPackage, ShopProduct } from '../../../../../shop-api-client';
import { CartPackage, CartProduct } from '../../../../../shop-api-client/models/Cart';
import { selectAccount } from '../../../../redux/selectors/account.selectors';
import { selectBackgroundSetMap } from '../../../../redux/selectors/background.selectors';
import { selectConfiguration } from '../../../../redux/selectors/configurations.selectors';
import { selectGallery } from '../../../../redux/selectors/gallery.selectors';
import {
  selectCanAutoAddMap,
  selectCanSelectAndBuy,
  selectInteractions,
} from '../../../../redux/selectors/interactions.selectors';
import {
  selectGroupedImageOptions,
  selectPriceSheet,
} from '../../../../redux/selectors/priceSheet.selectors';
import { useAppDispatch } from '../../../../redux/store';
import { addCartItem, updateCartItem } from '../../../../redux/thunks/cart.thunks';
import FixedMobileFooter from '../../../../shared/components/Mobile/FixedMobileFooter';
import { FREE, SELECT_YOUR_BACKGROUND } from '../../../../shared/constants';
import { getStripe } from '../../../../shared/constants/stripe';
import useSearchParams from '../../../../shared/hooks/useSearchParams';
import { Params } from '../../../../shared/types/router';
import { formatCurrency } from '../../../../shared/utils';
import CartItemDrawer from '../../CartItemDrawer';
import Backgrounds from '../../Configuration/shared/Backgrounds';
import useConfigurationRouter from '../../Configuration/useConfigurationRouter';
import {
  getBackground,
  isConfigurable,
  requiresPerImageBG,
  requiresPerProductBG,
  shapeEditPackage,
  shapeEditProduct,
  validateConfiguredItem,
} from '../../Configuration/utils';
import { DESCRIPTION, POSE_OPTIONS, PRODUCTS_GUTTER } from '../../constants';
import FittedProductImage from '../../FittedProductImage';
import OffersInstructions from '../../OffersInstructions';
import ThumbnailCarousel from '../../ThumbnailCarousel';
import {
  getBackgroundFromSets,
  getCustomizeLabel,
  getPoseDescription,
  getProductDescription,
  hasImageNodesWithBg,
} from '../../utils';
import DetailsSection from '../DetailsSection';
import PackageItemList from '../PackageItemList';
import { CatalogProductNode } from 'iq-product-render';

interface Props {
  isMobile?: boolean;
  product: ShopProduct | ShopPackage;
  error?: boolean;
}

const MOBILE_IMG_STYLES = {
  height: 'inherit',
  width: window.innerWidth,
};

const ProductDetailsMain = ({ isMobile, product, error }: Props) => {
  // Selectors

  const { currency } = useSelector(selectAccount);
  const { editPackage, editProduct } = useSelector(selectConfiguration);
  const { selectedBackground } = useSelector(selectInteractions);
  const {
    backgroundSets,
    categories,
    offersStatusMap,
    preOrderBackgroundSelectionType,
    productNodeMap,
  } = useSelector(selectPriceSheet);
  const { optionalImageOptions } = useSelector(selectGroupedImageOptions);
  const backgroundSetMap = useSelector(selectBackgroundSetMap);
  const canAutoAddMap = useSelector(selectCanAutoAddMap);
  const canSelectAndBuy = useSelector(selectCanSelectAndBuy);
  const gallery = useSelector(selectGallery);

  // Params required to initialize state
  const { getParamsValue } = useSearchParams();
  const cartPackageID = getParamsValue('cartPackageID');
  const cartProductID = getParamsValue('cartProductID');
  const bgFromParams = canSelectAndBuy[product.id] ? getParamsValue('bg') : '';
  const imgFromParams = canSelectAndBuy[product.id] ? getParamsValue('image') : '';

  // State
  const [background, setBackground] = useState<ShopBackground | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating] = useState(!!cartProductID || !!cartPackageID);
  const [isValidForCart, setIsValidForCart] = useState(true);
  const [selectedImage, setSelectedImage] = useState<string>();

  const { routeToConfigurationPath } = useConfigurationRouter();
  const { key } = useParams<Params>();
  const bgScrollToRef = useRef<HTMLDivElement>(null);
  const customizeBtnRef = useRef<HTMLButtonElement>(null);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const intl = useIntl();

  const getDisplayImages = useCallback(() => {
    if ('availableProducts' in product) {
      const filteredSubItems = product.availableProducts.filter(p => !!p.image);
      const subItemImages = filteredSubItems.map(subItem => subItem.image || '');

      const displayImages = product.image ? [product.image] : [];
      displayImages.push(...subItemImages);

      return displayImages;
    }
    return product.images || [];
  }, [product]);

  useEffect(() => {
    // Reset the display images whenever the product changes:
    setSelectedImage(getDisplayImages()[0]);
  }, [getDisplayImages]);

  // TODO SC-272: un-comment when criteria is provided for Option Details
  // const OPTIONS_AVAILABLE = intl.formatMessage({
  //   id: 'productDetailsMain.optionsAvailable',
  //   defaultMessage: 'OPTIONS AVAILABLE',
  // });
  const categoryOffers = offersStatusMap[product.categoryID];
  const { galleryConfig, images, isGreenScreen, isPreOrder } = gallery;
  const isLocked = categoryOffers?.isLocked;
  const isPackage = product.type === 'package-byop' || product.type === 'package';
  const mobileImageStyles = product.image && isMobile ? MOBILE_IMG_STYLES : {};

  const hasImgOptions = !!optionalImageOptions.length && product.type !== 'nonPrintProduct';

  let showBgSelection = false;
  if (product.type === 'product') {
    showBgSelection = hasImageNodesWithBg(productNodeMap[product.catalogProductID]!);
  } else if (product.type === 'package') {
    let imageNodes: CatalogProductNode[] = [];
    product.availableProducts.forEach(avialbleProduct => {
      const productNodes = productNodeMap[avialbleProduct.catalogProductID];
      if (productNodes) {
        imageNodes = imageNodes.concat(productNodes);
      }
    });
    showBgSelection = hasImageNodesWithBg(imageNodes);
  }

  const canEditPerProductBG = requiresPerProductBG(
    product.type,
    preOrderBackgroundSelectionType,
    isGreenScreen,
    isPreOrder,
    backgroundSets,
  );
  const canEditPerImageBG = requiresPerImageBG(
    product.type,
    preOrderBackgroundSelectionType,
    isGreenScreen,
    isPreOrder,
    backgroundSets,
  );
  const requiresBG = (canEditPerProductBG || canEditPerImageBG) && showBgSelection;

  const canAddToCart =
    !hasImgOptions &&
    !canEditPerImageBG &&
    (canAutoAddMap[product.id] || !isConfigurable(isPreOrder, product, productNodeMap));

  const selectAndBuyRequiresConfig = canSelectAndBuy[product.id] && !canAddToCart;
  const showBGSelection = !canSelectAndBuy[product.id] && requiresBG && canAddToCart;
  const customizeLabel = getCustomizeLabel(
    !!error,
    canAddToCart,
    isUpdating,
  );

  const isPkgWithDisplayImages =
    'availableProducts' in product && product.availableProducts.some(p => p.image);
  const isProductWithDisplayImages = product && product.images && product.images.length > 1;
  const showCarousel = isProductWithDisplayImages || isPkgWithDisplayImages;

  const displayImgMaxHeight = isMobile ? 400 : 500;

  const getCustomizeBtnRef = () => customizeBtnRef.current;

  const handleAddOrUpdate = async () => {
    setIsLoading(true);
    if (cartProductID || cartPackageID) {
      const { valid } = await dispatch(
        updateCartItem((editProduct as CartProduct) || (editPackage as CartPackage), key),
      );
      setIsLoading(false);
      if (!valid) {
        return;
      }
      history.push(`/${key}/carts`);
    } else {
      const image = images[imgFromParams];
      const bgForEditItem =
        background ||
        (image?.isGreenScreen
          ? getBackgroundFromSets(backgroundSets, parseInt(bgFromParams))
          : undefined);

      let editItem;

      if (product.type === 'package' || product.type === 'package-byop') {
        editItem = shapeEditPackage(product, productNodeMap, image, bgForEditItem, gallery);
      } else {
        editItem = shapeEditProduct(product, productNodeMap, image, bgForEditItem, gallery);
      }

      const isValid = validateConfiguredItem(
        editItem,
        product,
        productNodeMap,
        isPreOrder,
        requiresBG,
      );

      if (!isValid) {
        setIsValidForCart(false);
        bgScrollToRef.current?.scrollIntoView({ behavior: 'smooth' });
      } else {
        await dispatch(addCartItem(editItem));
      }

      setIsLoading(false);
    }
  };

  const handleBackgroundSelect = (background: ShopBackground) => {
    if (!isValidForCart) {
      setIsValidForCart(true);
    }
    setBackground(background);
  };

  const handleRouteToCustomize = () => {
    routeToConfigurationPath(key, product, gallery, selectAndBuyRequiresConfig);
  };

  const handleCustomizeBtnClick = () => {
    if (canAddToCart) {
      handleAddOrUpdate();
    } else {
      handleRouteToCustomize();
    }
  };

  // ------------------------ Render Functions ------------------------

  const renderAffirmElement = () => {
    if (
      !galleryConfig ||
      !galleryConfig.affirmEnabled ||
      product.price < galleryConfig.minAffirmAmount
    ) {
      return null;
    }

    return (
      <Box marginBottom={isMobile ? 4 : undefined}>
        <Elements stripe={getStripe()}>
          <PaymentMethodMessagingElement
            options={{
              amount: Math.round(product.price * 100),
              currency: 'USD',
              paymentMethodTypes: ['affirm'],
              countryCode: 'US',
            }}
          />
        </Elements>
      </Box>
    );
  };

  const renderBackgroundSelection = () => {
    const editItem = editProduct || editPackage;

    const selectedEditItemBG = editItem
      ? getBackground(editItem, Object.values(backgroundSetMap))
      : undefined;

    return (
      <Box marginTop={8} position="relative">
        {/* The box below offsets `scrollIntoView` to give a greater buffer at the top of the parent */}
        <Box ref={bgScrollToRef} position="absolute" top={isMobile ? -100 : -5} />
        <Heading color={isValidForCart ? 'black' : 'error'} marginY={5} size="xs">
          {SELECT_YOUR_BACKGROUND.toUpperCase()}
        </Heading>
        <Backgrounds
          editItem={editItem}
          isInvalid={!isValidForCart}
          onSelect={handleBackgroundSelect}
          selectedBackground={selectedEditItemBG || selectedBackground}
          setOnEditItem={false}
          showHeading={false}
        />
      </Box>
    );
  };

  const renderCustomizeButton = () => (
    <Button
      data-test={`product-details-main-customize-button-${product.name}`}
      disabled={isLocked || isLoading || error}
      fontSize="sm"
      isLoading={isLoading}
      loadingText={customizeLabel}
      marginY={0}
      onClick={handleCustomizeBtnClick}
      ref={customizeBtnRef}
      width="100%"
    >
      {customizeLabel}
    </Button>
  );

  const renderHeading = () => (
    <Box textAlign="left">
      <Heading size="lg" data-test={`product-details-main-product-name-${product.id}`}>
        {product.name}
      </Heading>
      <Text
        color="brand"
        data-test={`product-details-main-product-price-${product.id}`}
        fontFamily="ITC Avant Garde Gothic Demi"
        fontSize="18px"
        marginY="5px"
      >
        {formatCurrency(product.price, currency)}
      </Text>
      {renderAffirmElement()}
    </Box>
  );

  const renderOfferDetails = () => (
    <Box marginTop={5}>
      {isLocked && (
        <Box marginBottom={4}>
          <Text fontSize="sm" fontStyle="italic" lineHeight={2} marginLeft={2}>
            {intl.formatMessage({
              id: 'productDetailsMain.itemIsLocked',
              defaultMessage: 'This item is locked and cannot be purchased yet.',
            })}
          </Text>
          <Text fontSize="sm" fontStyle="italic" lineHeight={2} marginLeft={2}>
            {intl.formatMessage({
              id: 'productDetailsMain.toUnlockThisItem',
              defaultMessage: 'To unlock this item, follow the steps below.',
            })}
          </Text>
        </Box>
      )}
      <OffersInstructions category={categories[product.categoryID]} productID={product.id} />
    </Box>
  );

  return (
    <Flex
      direction={isMobile ? 'column' : 'row'}
      flex="auto"
      width={`calc(100vw - ${PRODUCTS_GUTTER * 2}px)`}
    >
      <CartItemDrawer />
      <Box width={isMobile ? '100%' : '60%'} paddingBottom="80px" paddingX={[0, '16px']}>
        {isMobile && renderHeading()}
        <Flex
          align="start"
          maxHeight="600px"
          minHeight="400px"
          height={showCarousel ? `${displayImgMaxHeight}px` : undefined}
        >
          {selectedImage && (
            <FittedProductImage
              image={selectedImage}
              isLocked={isLocked}
              maxHeight={showCarousel ? displayImgMaxHeight : 600}
              opacity={error ? 0.25 : 1}
              product={product}
              {...mobileImageStyles}
            />
          )}
        </Flex>

        {showCarousel && selectedImage && (
          <ThumbnailCarousel
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            images={getDisplayImages()}
          />
        )}
      </Box>
      <Flex direction="column" paddingX={isMobile ? 0 : '20px'} width={isMobile ? '100%' : '40%'}>
        {!isMobile && renderHeading()}
        {isPackage && (
          <>
            <PackageItemList packageID={product.id} />
            {product.type !== 'package' && <PackageItemList packageID={product.id} notIncluded />}
          </>
        )}
        <DetailsSection
          heading={DESCRIPTION}
          headingFontSize="sm"
          hideBorder={!isPackage && !product.options.length}
          marginBottom={showBGSelection ? 0 : 'initial'}
        >
          <Box marginBottom={5}>
            <Text
              fontSize="md"
              marginTop="8px"
              data-test={`product-details-main-product-description-${product.id}`}
            >
              {getProductDescription(product)}
            </Text>
            {showBGSelection && renderBackgroundSelection()}
            <Box marginTop={10}>{renderCustomizeButton()}</Box>
            {!!categoryOffers && renderOfferDetails()}
          </Box>
        </DetailsSection>
        {isPackage && (
          /* TODO SC-272: When the product option details is restored, replace the opening DetailsSection tag with the commented one below
            <DetailsSection heading={POSE_OPTIONS} headingFontSize="sm" isCollapsible hideBorder={!product.availableProducts.some(p => p.options.length)}>
          */
          <DetailsSection heading={POSE_OPTIONS} headingFontSize="sm" isCollapsible hideBorder>
            <Text
              fontSize="md"
              marginBottom={3}
              marginTop="8px"
              data-test={`product-details-main-product-pose-description-${product.name}`}
            >
              {getPoseDescription(product, currency)}
            </Text>
          </DetailsSection>
        )}
        {/* TODO SC-272: Un-comment after MVP when criteria is provided  */}
        {/* {!!product.options.length && (
          <DetailsSection heading={OPTIONS_AVAILABLE} headingFontSize="sm" isCollapsible>
            WIP: Include option details
          </DetailsSection>
        )} */}
      </Flex>
      {isMobile && (
        <FixedMobileFooter
          buttonText={customizeLabel}
          disabled={isLocked || error}
          getTargetElem={getCustomizeBtnRef}
          key={product.id}
          label={product.price ? formatCurrency(product.price, currency) : FREE}
          onButtonClick={handleCustomizeBtnClick}
        />
      )}
    </Flex>
  );
};

export default ProductDetailsMain;
