import {
  Box,
  BoxProps,
  Flex,
  Heading,
  HStack,
  SimpleGrid,
  SimpleGridProps,
  Text,
  useBreakpointValue,
  Wrap,
} from '@chakra-ui/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import {
  ImageRequirementType,
  ShopBackground,
  ShopImage,
} from '../../../../../../../../shop-api-client';
import { selectBackgroundsMap } from '../../../../../../../redux/selectors/background.selectors';
import { selectCart } from '../../../../../../../redux/selectors/cart.selectors';
import { selectEditItemAvailableImages } from '../../../../../../../redux/selectors/configurations.selectors';
import { selectGallery } from '../../../../../../../redux/selectors/gallery.selectors';
import { useAppSelector } from '../../../../../../../redux/store';
import Swatch from '../../../../../../../shared/components/BackgroundSwatches/Swatch';
import ThumbnailWithBackground from '../../../../../../../shared/components/ThumbnailWithBackground';
import { ImageAndBackground } from '../../../../../../../shared/types/image';
import { Params } from '../../../../../../../shared/types/router';
import { validateImageRequirement } from '../../../../../utils';
import { THUMB_GRID_MAX_HEIGHT, THUMB_GRID_MAX_WIDTH } from '../../../../constants';

interface Props extends SimpleGridProps {
  basicImages: string[];
  boxProps?: BoxProps;
  heading: string;
  imageMaxHeight?: string;
  imageMaxWidth?: string;
  imageRequirementType?: ImageRequirementType | null;
  selectedImagesCounter?: Record<string, number>;
  selectedImages: Record<string, ImageAndBackground>;
  selectImage(img: ShopImage, background?: ShopBackground): void;
  showAsHorizontalList?: boolean;
  showErrorOutline?: boolean;
}

// PNG bg string for gs images, since we don't render the bg
const PNG_BG_SVG = `rgb(250, 250, 250) url('data:image/svg+xml,\
<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" fill-opacity=".05" >\
         <rect x="4" width="4" height="4" />\
         <rect y="4" width="4" height="4" />\
         </svg>')`;

/**
 * Renders all available images (excluding favorites)
 * These all require full user configuration upon selection depending on GS/crop status
 */
const ImageNodeDisplayBasic = ({
  basicImages,
  boxProps,
  heading,
  imageMaxHeight = THUMB_GRID_MAX_HEIGHT,
  imageMaxWidth = THUMB_GRID_MAX_WIDTH,
  imageRequirementType,
  selectedImagesCounter,
  selectedImages,
  selectImage,
  showAsHorizontalList,
  showErrorOutline,
  ...rest
}: Props) => {
  const { key } = useParams<Params>();
  const { images, type } = useAppSelector(state => selectGallery(state, key));
  const { products: editItemToImageMap } = useAppSelector(selectEditItemAvailableImages);
  const { shopFavorites } = useAppSelector(state => selectCart(state, key));
  const backgroundsMap = useAppSelector(selectBackgroundsMap);
  const isMobile = useBreakpointValue({ base: true, md: false });

  if (!basicImages.length) {
    return null;
  }

  const basicShopImages = basicImages.map(i => images[i]);
  const availableImages = Object.values(editItemToImageMap).flat();

  // Filter out images that don't meet req type AND are non-GS favorite images
  const validImages = basicShopImages.filter(image => {
    const isAvailable = availableImages.some(i => i.internalName === image.internalName);
    if (!isAvailable) {
      return false;
    }
    if (type === 'standard') {
      return true;
    }
    // Only matching favorite if not GS and image ID matches
    const isNonGSFavorite = shopFavorites.some(
      fav => fav.imageName === image.internalName && !image.isGreenScreen,
    );
    if (isNonGSFavorite) {
      return false;
    }
    return validateImageRequirement(image, imageRequirementType);
  });

  if (!validImages.length) {
    return null;
  }

  // map of selected images keyed by imageName IF GS IMAGE
  // array of background IDs associated with a GS image
  // FAVORITES ARE EXCLUDED
  const selectedImgBgToImgMap = Object.values(selectedImages).reduce<Record<string, number[]>>(
    (map, si) => {
      const isFavorite = shopFavorites.some(
        fav => fav.imageName === si.imageName && fav.backgroundID === si.backgroundID,
      );
      if (!isFavorite && si.backgroundID) {
        if (map[si.imageName]) {
          map[si.imageName].push(si.backgroundID);
        } else {
          map[si.imageName] = [si.backgroundID];
        }
      }

      return map;
    },
    {},
  );

  const renderBgsForSelectedImg = (imageName: string) => {
    const backgrounds = selectedImgBgToImgMap[imageName];

    // 1. check if image is even a selected image with selected bgs
    if (!backgrounds.length) {
      return;
    }

    return (
      <Wrap justifyContent="flex-start" alignItems="flex-start" width="100%" paddingX={1}>
        {backgrounds.map(bg => (
          <Swatch
            key={bg}
            position="relative"
            size="15px"
            borderRadius="3px"
            url={`url(${backgroundsMap[bg].sources.thumb}) no-repeat`}
            data-test="background-swatches-images"
          />
        ))}
      </Wrap>
    );
  };

  const renderImages = () => {
    return validImages.map(image => {
      // Image is selected if it's a non GS image with undefined BG
      const isSelectedNonGS = !!selectedImages[`${image.internalName}-undefined`];
      // or we have a value in the map png GS image to bg map
      const isSelectedGS = !!selectedImgBgToImgMap[image.internalName];
      // isSelected if either condition is met
      const isSelected = isSelectedGS || isSelectedNonGS;
      const imageCounter = selectedImagesCounter?.[image.internalName];

      let borderColor = showErrorOutline ? 'error' : 'transparent';
      if (isSelected) {
        borderColor = 'brand';
      }

      const borderRadius = isMobile ? '3px' : '5px';
      const borderWidth = isMobile ? '3px' : '4px';
      const borderPadding = isMobile ? '2px' : '4px';

      // TODO: this entire render can probably be turned into a shared compoennt
      // between imageNodeBasic, imagenNodePreset, imageOptionDisplay, imageNodeBackgroundDisplay
      return (
        <Flex key={image.internalName} direction="column">
          <Flex
            alignItems="center"
            direction="column"
            marginBottom={showAsHorizontalList ? 3 : 2}
            flex={1}
            height="100%"
          >
            <Flex align="center" flex={1} marginX={showAsHorizontalList ? '4px' : undefined}>
              <Box
                data-test={`shop-photo-selection-${image.displayName}`}
                borderColor={borderColor}
                borderRadius={borderRadius}
                borderWidth={borderWidth}
                padding={borderPadding}
              >
                <ThumbnailWithBackground
                  src={image.sources.thumb}
                  isActive={isSelected}
                  badgeText={imageCounter ? `${imageCounter}` : undefined}
                  containerOnClick={() => selectImage(image)}
                  containerStyles={{
                    background: PNG_BG_SVG,
                    backgroundRepeat: 'repeat',
                  }}
                  maxHeight={imageMaxHeight}
                  maxWidth={imageMaxWidth}
                  showBadge={!!imageCounter}
                  _hover={{
                    cursor: 'pointer',
                  }}
                />
              </Box>
            </Flex>
            {!showAsHorizontalList && (
              <Text fontSize="xs" noOfLines={1} maxWidth={imageMaxWidth}>
                {image.displayName}
              </Text>
            )}
          </Flex>
          {!showAsHorizontalList && selectedImgBgToImgMap[image.internalName] && (
            <Box height="23px">{renderBgsForSelectedImg(image.internalName)}</Box>
          )}
        </Flex>
      );
    });
  };

  return (
    <Box {...boxProps}>
      {!showAsHorizontalList && (
        <Heading size="xs" marginY={2}>
          {heading} {`(${validImages.length})`}
        </Heading>
      )}
      {showAsHorizontalList ? (
        <HStack {...rest}>{renderImages()}</HStack>
      ) : (
        <SimpleGrid columns={{ base: 2, md: 3 }} {...rest}>
          {renderImages()}
        </SimpleGrid>
      )}
    </Box>
  );
};

export default ImageNodeDisplayBasic;
