import { Box, Flex, Heading, Spinner, Text, useBreakpointValue } from '@chakra-ui/react';
import { CatalogProductNode } from 'iq-product-render';
import { noop } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  ShopProduct,
  ShopProductCollection,
  ShopProductPrint,
} from '../../../../../shop-api-client';
import {
  CartCollectionReq,
  CartDownloadReq,
  CartPrintProduct,
  CartPrintProductReq,
  CartProduct,
  CreateCartProductReq,
} from '../../../../../shop-api-client/models/Cart';
import { selectAccount } from '../../../../redux/selectors/account.selectors';
import { selectCart } from '../../../../redux/selectors/cart.selectors';
import {
  selectActiveStep,
  selectConfiguration,
  selectEditItemImageMap,
  selectSteps,
} from '../../../../redux/selectors/configurations.selectors';
import { selectGallery } from '../../../../redux/selectors/gallery.selectors';
import { selectInteractions } from '../../../../redux/selectors/interactions.selectors';
import {
  selectGroupedImageOptions,
  selectPriceSheet,
} from '../../../../redux/selectors/priceSheet.selectors';
import { setEditProduct } from '../../../../redux/slices/configurations.slice';
import { toggleShowFooter } from '../../../../redux/slices/interactions.slice';
import { useAppDispatch, useAppSelector } from '../../../../redux/store';
import { addCartItem, updateCartItem } from '../../../../redux/thunks/cart.thunks';
import { getRendererProducts } from '../../../../redux/thunks/catalog.thunks';
import { initializeEditItem } from '../../../../redux/thunks/configuration.thunks';
import {
  initializeSelectAndBuy,
  loadBackgroundImage,
} from '../../../../redux/thunks/interactions.thunks';
import DisabledOverlay from '../../../../shared/components/DisabledOverlay';
import useIsElemInView from '../../../../shared/hooks/useIsElemInView';
import useProductFontLoader from '../../../../shared/hooks/useProductFontLoader';
import useSearchParams from '../../../../shared/hooks/useSearchParams';
import useToast from '../../../../shared/hooks/useToast';
import { Params } from '../../../../shared/types/router';
import { formatCurrency } from '../../../../shared/utils';
import CartItemDrawer from '../../CartItemDrawer';
import { PRODUCTS_GUTTER } from '../../constants';
import ProductsBreadCrumb from '../../ProductsBreadCrumb';
import { REQUIRED_FIELDS } from '../constants';
import CollectionSelection from '../shared/CollectionSelection';
import ConfigurationPreview from '../shared/ConfigurationPreview';
import BackgroundSelection from '../shared/configurationSections/BackgroundSelection';
import ProductConfigSection from '../shared/configurationSections/ProductConfigSection';
import ConfigurationSummary from '../shared/ConfigurationSummary';
import ImageNodesEditor from '../shared/ImageNodesEditor';
import ImageNodeMulti from '../shared/ImageNodesEditor/ImageNodeMulti';
import ImageToneSelection from '../shared/ImageToneSelection';
import OptionalSection from '../shared/OptionalSection';
import Toolbar from '../shared/Toolbar';
import {
  getCollectionImagesStep,
  getConfigurableImageNodes,
  getGroupedProductOptions,
  getImageMultiNodeStep,
  getImageNodesStep,
  getImageToneStep,
  getPreOrderBackgroundsStep,
  validateImgNodes,
} from '../utils';
import { hasImageNodesWithBg } from '../../utils';

const EDITOR_WIDTH = { base: '100%', md: '45%', lg: '40%' }; // TODO: adjust for mobile as necessary
const MAX_WIDTH = 1200;
const SUMMARY_BTN_OFFSET = 72;

const ProductConfiguration = () => {
  const { key, productID } = useParams<Params>();

  // NOTE: Get cart with key from params, since `currentVisitKey` is set in ProtectedApp after
  // selectCart is computed:
  const { cartProducts } = useAppSelector(state => selectCart(state, key));
  const { completedSteps, editProduct, editStep } = useSelector(selectConfiguration);
  const { images, isPreOrder } = useSelector(selectGallery);
  const { optionalImageOptions } = useSelector(selectGroupedImageOptions);
  const { buyModeImage } = useSelector(selectInteractions);
  const { offersStatusMap, products, productNodeMap, preOrderBackgroundSelectionType } =
    useSelector(selectPriceSheet);
  const { accountID, currency } = useSelector(selectAccount);
  const editItemImagesMap = useSelector(selectEditItemImageMap);
  const activeStep = useSelector(selectActiveStep);
  const steps = useSelector(selectSteps);

  const [isAddingOrUpdating, setIsAddingOrUpdating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [ready, setReady] = useState(false);

  const [areFontsLoaded, setFonts] = useProductFontLoader();
  const { getParamsValue } = useSearchParams();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const intl = useIntl();
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const shopProduct = products[productID!];
  const summaryRef = useRef<HTMLDivElement>(null);
  const isSummaryInView = useIsElemInView(
    summaryRef.current,
    summaryRef.current ? summaryRef.current.clientHeight - SUMMARY_BTN_OFFSET : 0,
  );
  const toast = useToast();

  const { optionalProductOptions, requiredProductOptions } = getGroupedProductOptions(
    shopProduct.options,
  );

  const requiredImageSelection = intl.formatMessage({
    id: 'productConfiguration.requiredImageSelection',
    defaultMessage: 'Please select your images for this product',
  });

  // Search params values
  const bgFromParams = getParamsValue('bg');
  const cartProductID = getParamsValue('cartProductID');
  const imageFromParams = getParamsValue('image');

  const hasOptional = !!(optionalProductOptions.length || optionalImageOptions.length);
  const isIncludeAll = (shopProduct as ShopProductCollection).includeAll;
  const originatingKey = getParamsValue('originatingKey');

  const stepsMap = useMemo(
    () =>
      steps.reduce<Record<string, boolean>>((map, step) => {
        map[step] = true;
        return map;
      }, {}),
    [steps],
  );

  const prodcutNodes = productNodeMap[(shopProduct as ShopProduct).catalogProductID];

  const { current: catalogNodes } = useRef(
    getConfigurableImageNodes(
      shopProduct as ShopProductPrint,
      prodcutNodes || [],
    ),
  );

  const backgroundStep = editProduct?.id
    ? getPreOrderBackgroundsStep(editProduct.id, preOrderBackgroundSelectionType)
    : undefined;
  const hasBackgroundStep = !!backgroundStep && steps.includes(backgroundStep);
  const hasBackgrounds = Object.values(editItemImagesMap).some(backgroundSet => backgroundSet.size);
  const isCollectionOrDownload = ['collection', 'imageDownload'].includes(editProduct?.type || '');
  const displayBGStep =
    hasBackgroundStep && (hasImageNodesWithBg(catalogNodes) || isCollectionOrDownload);

  const areImagesConfigured = useCallback(() => {
    if (!editProduct) {
      return false;
    }

    if (isPreOrder) {
      return true;
    }

    if (editProduct.type === 'product') {
      const validateNodes = editProduct.nodes.map(n => ({
        ...n,
        skipBackgroundSelection: catalogNodes.find(cn => cn.id === n.catalogNodeID)
          ?.skipBackgroundSelection,
      }));
      return validateImgNodes(validateNodes, images, productNodeMap, shopProduct as ShopProduct);
    }

    if (shopProduct.type === 'imageDownload' || shopProduct.type === 'collection') {
      // It's possible for the Add to Cart button to be available while the
      // user has not selected their images yet, check that here:
      if (shopProduct.includeAll) {
        // Images don't need to be selected, we're done:
        return true;
      }

      // Filter down to actually selected images:
      const selectedImages = (editProduct as CartDownloadReq).collectionImages.filter(
        ci => !!ci.internalName,
      );
      return selectedImages.length >= shopProduct.minImages;
    }

    return true;
  }, [editProduct, images, isPreOrder, shopProduct, catalogNodes, productNodeMap]);

  const isAllUnlocked = useCallback(() => {
    if (!editProduct || editStep) {
      return false;
    }

    return steps.every(step => completedSteps[step]);
  }, [completedSteps, editProduct, editStep, steps]);

  // On init, sets the editProduct, fetches the full catalog product and
  // sets required & optional product options
  useEffect(() => {
    // If the editItem is loaded and has not changed, return:
    if (isLoading || (ready && editProduct?.priceSheetItemID === parseInt(productID!))) {
      return;
    }

    // If the editItem is loaded, and not editing an existing cart item, check whether
    // it's a locked item:
    if (!cartProductID && editProduct) {
      if (offersStatusMap[shopProduct.categoryID]?.isLocked) {
        // If the item is locked, send them to the details page:
        history.replace(`/${key}/shop/${shopProduct.categoryID}/product/${shopProduct.id}`);
        return;
      }
    }

    const init = async () => {
      setIsLoading(true);
      if (!editProduct || editProduct.priceSheetItemID !== parseInt(productID!)) {
        let cartItem;

        if (cartProductID) {
          cartItem = cartProducts.find(p => p.id === parseInt(cartProductID));
        }

        if (imageFromParams && !buyModeImage) {
          dispatch(initializeSelectAndBuy(imageFromParams, parseInt(bgFromParams)));
        }

        const { valid, error } = dispatch(
          initializeEditItem({
            shopItem: shopProduct,
            cartItem,
            imageName: imageFromParams,
            backgroundID: parseInt(bgFromParams),
          }),
        );

        if (!valid) {
          return toast({ title: error });
        }
      }
      const { payload } = await dispatch(getRendererProducts([shopProduct]));

      const allNodes = (payload || []).reduce<CatalogProductNode[]>(
        (result, product) => {
          // non-print products don't have nodes
          if(!product.nodes){
            return result;
          }
          return [...result, ...product.nodes]
        },
        [],
      );

      await setFonts(allNodes);
      setReady(true);
      setIsLoading(false);
    };

    init();
  }, [
    bgFromParams,
    buyModeImage,
    cartProductID,
    cartProducts,
    dispatch,
    editProduct,
    history,
    imageFromParams,
    isLoading,
    key,
    offersStatusMap,
    productID,
    ready,
    setFonts,
    shopProduct,
    toast,
  ]);

  // Google Analytics view_item event.
  useEffect(() => {
    window.gtag('event', 'view_item', {
      items: [
        {
          item_id: shopProduct.id,
          item_name: shopProduct.name,
          item_category: shopProduct.type,
          affiliation: accountID,
          price: shopProduct.price,
        },
      ],
    });
  }, [shopProduct, accountID]);

  useEffect(() => {
    dispatch(toggleShowFooter(false));
    return () => {
      // Reset initial `isLoading` and `ready` to prepare for the next item for configuration:
      setIsLoading(true);
      setReady(false);
      dispatch(loadBackgroundImage(null));
      dispatch(toggleShowFooter(true));
    };
  }, [dispatch]);

  // Return null if no editProduct, fonts are not ready, or if editProduct data is stale, which is
  // possible when selecting another item for configuration from the CartItemDrawer, after adding
  // the currently configured item to the cart:
  if (!editProduct || !areFontsLoaded || editProduct.priceSheetItemID !== parseInt(productID!)) {
    return null;
  }

  const handleSave = (updated: CreateCartProductReq) => {
    dispatch(setEditProduct(updated));
  };

  const handleAddOrUpdate = async () => {
    if (!areImagesConfigured()) {
      return toast({ title: requiredImageSelection });
    }

    // If not all editor sections are unlocked, visitor should not add to cart. This check
    // guards against visitor potentially deleting the overlay elem in dev tools then pressing
    // the add button when it is not valid
    if (!isAllUnlocked()) {
      return toast({ title: REQUIRED_FIELDS });
    }

    setIsAddingOrUpdating(true);
    if (cartProductID) {
      const { valid } = await dispatch(updateCartItem(editProduct as CartProduct, key));
      if (valid) {
        // Return to the gallery from which the visitor chose to edit the cart item:
        history.push(`/${originatingKey}/carts`);
      }
    } else {
      await dispatch(addCartItem(editProduct, undefined));
    }
    setIsAddingOrUpdating(false);
  };

  const handleDrawerClose = () => {
    const categoryID = shopProduct.categoryID;
    history.push(`/${key}/shop/${categoryID}/product/${editProduct.priceSheetItemID}`);
  };

  const renderEditor = () => {
    if (!ready) {
      return (
        <Flex align="center" justify="center" height="100%" width="100%">
          <Spinner />
        </Flex>
      );
    }

    return (
      <Box>
        <>
          {hasBackgroundStep && displayBGStep && <BackgroundSelection editItem={editProduct} />}
          {(stepsMap[getImageNodesStep(editProduct.id!)] ||
            stepsMap[getImageMultiNodeStep(editProduct.id!)]) && (
            <ImageNodesEditor
              catalogProductID={(shopProduct as ShopProductPrint).catalogProductID}
              editProduct={editProduct as CartPrintProductReq}
              shopProduct={shopProduct as ShopProductPrint}
            />
          )}
          {stepsMap[getCollectionImagesStep(editProduct.id!)] && (
            <CollectionSelection
              editProduct={editProduct as CartCollectionReq | CartDownloadReq}
              shopProduct={shopProduct as ShopProductCollection}
            />
          )}
          {stepsMap[getImageToneStep(editProduct.id!)] && (
            <ImageToneSelection editProduct={editProduct as CartPrintProductReq} />
          )}
          <ProductConfigSection
            activeStep={activeStep}
            editProduct={editProduct}
            onSave={handleSave}
            onPostProcessing={noop}
            shopProduct={shopProduct as ShopProduct}
          />
          {!isIncludeAll && hasOptional && (
            <OptionalSection
              optionalImageOptions={optionalImageOptions}
              optionalProductOptions={optionalProductOptions}
              useOther={!!requiredProductOptions.length}
              onSave={handleSave}
              editItem={editProduct}
              isDisabled={!isAllUnlocked()}
            />
          )}
          <Box ref={summaryRef} position="relative">
            <DisabledOverlay isDisabled={!isAllUnlocked()} />
            <ConfigurationSummary
              hasAdditionalPoses={false}
              hasBackgrounds={(hasBackgroundStep || hasBackgrounds) && displayBGStep}
              hasProductOptions={!!shopProduct.options.length}
              isAddingOrUpdating={isAddingOrUpdating}
              onAddToCart={handleAddOrUpdate}
              showUpdateText={!!cartProductID}
            />
          </Box>
        </>
      </Box>
    );
  };

  // The multi-node designer replaces the regular product view during its image node step
  if (activeStep === getImageMultiNodeStep(editProduct.id!)) {
    return (
      <ImageNodeMulti
        catalogNodes={catalogNodes}
        complete={false}
        editProduct={editProduct as CartPrintProductReq | CartPrintProduct}
        shopProduct={shopProduct as ShopProductPrint}
      />
    );
  }

  return (
    <Flex align="center" direction="column" height="100%" paddingX={`${PRODUCTS_GUTTER}px`}>
      <CartItemDrawer onClose={handleDrawerClose} />
      <Flex direction={{ base: 'column', md: 'row' }} maxWidth={`${MAX_WIDTH}px`} width="100%">
        <Flex
          alignSelf="flex-start"
          bottom={0}
          direction="column"
          height={{ base: 'fit-content', md: '100vh' }}
          position={{ base: 'relative', md: 'sticky' }}
          top={0}
          width={{ base: '100%', md: '55%', lg: '60%' }}
        >
          <ProductsBreadCrumb showBuy />
          <ConfigurationPreview editItem={editProduct} shopItem={shopProduct} />
        </Flex>
        <Box
          paddingBottom="150px"
          paddingTop={{ base: 6, md: '90px' }}
          paddingX={isMobile ? 0 : '20px'}
          width={EDITOR_WIDTH}
        >
          <Heading size="lg">
            {intl.formatMessage(
              {
                id: 'productConfiguration.buyProduct',
                defaultMessage: 'Buy {product}',
              },
              {
                product: shopProduct.name,
              },
            )}
          </Heading>
          <Text data-test="product-price" color="brand" fontSize="sm">
            {formatCurrency(shopProduct.price, currency)}
          </Text>
          {renderEditor()}
        </Box>
      </Flex>
      <Toolbar
        isAddingOrUpdating={isAddingOrUpdating}
        onClick={handleAddOrUpdate}
        show={!isSummaryInView && isAllUnlocked()}
        showUpdateText={!!cartProductID}
      />
    </Flex>
  );
};

export default ProductConfiguration;
